<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-start px-3 mb-4">
        <div class="col-md-6"><h2>Pengurusan Penilaian</h2></div>
      </div>

      <div class="row align-items-start px-3">
        <div class="col-md-6 mt-2"><h4>Senarai Soalan</h4></div>
        <!-- <div class="col-md-6">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-secondary w-50 me-2"
              data-bs-toggle="modal"
              data-bs-target="#addSurveyQuestionModal"
            >
              Tambah Soalan +
            </button>
          </div>
        </div> -->
      </div>
    </div>

    <div
      class="container-header mt-4 mb-4 p-3"
      v-for="(section, l) in sectionList"
      :key="section + l"
    >
      <div class="container bg-white p-3 mb-4">
        <h5>Bahagian {{ section.toUpperCase() }}</h5>
        <h6>
          {{
            section == "a"
              ? "Soalan berkenaan diri anda"
              : "Berkenaan pengalaman anda sewaktu kursus dan ujian"
          }}
        </h6>
      </div>
      <div
        class="container bg-white p-3 mb-2"
        v-for="(q, i) in surveyQuestions[section]"
        :key="q + i"
      >
        <div class="row mx-0">
          <div class="col-md-1">
            <h5 class="mt-3">{{ i + 1 }}.</h5>
          </div>
          <div class="col-md-8">
            <h5 class="m-0 p-3 border">{{ q.question }}</h5>

            <div v-if="q.question_type == 'dropdown'">
              <div class="form-group mt-3">
                <label class="control-label mb-1">Pilihan Jawapan</label>
                <select class="form-select">
                  <option v-for="(a, i) in q.answer" :key="a + i">
                    {{ a.text }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="q.question_type == 'radio'">
              <div class="form-group mt-3">
                <div
                  class="form-check form-check-inline"
                  v-for="(a, i) in q.answer"
                  :key="a + i"
                >
                  <input class="form-check-input" type="radio" disabled />
                  <label class="form-check-label">{{ a.text }}</label>
                </div>
              </div>
            </div>

            <div v-if="q.question_type == 'free'">
              <div class="form-group mt-3">
                <label class="control-label mb-1">Jawapan</label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Jawapan anda..."
                ></textarea>
              </div>
            </div>

            <div v-if="q.question_type == 'sub'">
              <div
                class="container bg-white p-3 mb-2"
                v-for="(subQ, i) in q.subquestion"
                :key="subQ + i"
              >
                <div class="row mx-0">
                  <div class="col-md-1">
                    <h5 class="mt-3">{{ i + 1 }}.</h5>
                  </div>
                  <div class="col-md-8">
                    <h5 class="m-0 p-3 border">{{ subQ.question }}</h5>

                    <div class="form-group mt-3">
                      <div
                        class="form-check form-check-inline"
                        v-for="(subA, i) in subQ.answer"
                        :key="subA + i"
                      >
                        <input class="form-check-input" type="radio" disabled />
                        <label class="form-check-label">{{ subA.text }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="float-end" v-if="q.isEdit">
                      <button
                        @click="q.subquestion.splice(i, 1)"
                        class="btn btn-danger me-2"
                        style="font-size: 20px"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4 mb-2" v-if="q.question_type != 'free' && q.isEdit">
              <a
                data-bs-toggle="modal"
                data-bs-target="#addAnswerModal"
                @click="openAddAnotherAnswer(q, i)"
                class="text-decoration-underline"
              >
                Tambah Jawapan +
              </a>
            </div>
          </div>
          <div class="col-md-3" style="position: relative">
            <div class="float-end" v-if="!q.isEdit">
              <button
                @click="deleteSurveyQuestion(q, i)"
                class="btn btn-danger me-2"
                style="font-size: 20px"
              >
                <i class="bi bi-trash"></i>
              </button>
              <button
                @click="() => { selectedSection = section; q.isEdit = !q.isEdit}"
                class="btn btn-success"
                style="font-size: 20px"
              >
                <i class="bi bi-pencil"></i>
              </button>
            </div>
            <div class="float-end" v-else>
              <button
                @click="openAddSubQuestion(q, i)"
                data-bs-toggle="modal"
                data-bs-target="#addSubQuestion"
                class="btn btn-outline-secondary"
                style="font-size: 20px"
              >
                Tambah Sub-Soalan +
              </button>
            </div>
            <div
              style="position: absolute; right: 0; bottom: 0"
              v-if="q.isEdit"
            >
              <button @click="q.isEdit = !q.isEdit" class="btn btn-danger me-2">
                Batal&nbsp;&nbsp;<i class="bi bi-x-lg"></i>
              </button>
              <button @click="submitAddSubQuestion" class="btn btn-success">
                Simpan&nbsp;&nbsp;<i class="bi bi-check2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="addAnswerModal"
      tabindex="-1"
      aria-labelledby="addAnswerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-md-12">
                <h5>Tambah Jawapan</h5>
                <div class="form-group mt-3">
                  <div>
                    <label for="name" class="control-label mt-1"
                      >Pilihan Jawapan</label
                    >
                    <a class="mb-2 float-end" @click="addAnotherAnswer"
                      >Tambah Pilihan Jawapan +</a
                    >
                  </div>
                  <div
                    class="row mx-0 mb-2"
                    style="width: 100%"
                    v-for="(item, i) in answersForm"
                    :key="'answer' + i"
                  >
                    <div class="col-11 mx-0 px-0">
                      <input
                        type="text"
                        class="form-control pe-0"
                        v-model="item.text"
                        placeholder="Masukkan jawapan..."
                      />
                    </div>
                    <div class="col-1 mx-0 pe-0">
                      <label
                        @click="answersForm.splice(i, 1)"
                        class="float-end"
                        style="font-size: 1.7rem !important; color: red"
                      >
                        <i class="bi bi-trash"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4 justify-content-end">
            <button
              type="button"
              style="width: auto"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              style="width: auto"
              class="btn btn-success"
              @click="submitAddAnswer()"
            >
              Tambah
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      id="addSubQuestion"
      tabindex="-1"
      aria-labelledby="addSubQuestionLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mx-0">
              <div class="col-md-12">
                <h5>Tambah Sub Soalan</h5>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1">Nama</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan nama"
                    v-model="subQForm.question"
                  />
                </div>
                <div class="form-group mt-3">
                  <div
                    class="form-check"
                    v-for="(scale, i) in scaleList"
                    :key="'scale' + i"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      :value="scale.answer"
                      v-model="subQForm.answer"
                    />
                    <label class="form-check-label">
                      {{ scale.value }}
                    </label>
                    <div class="form-group mt-3">
                      <div
                        class="form-check form-check-inline"
                        v-for="(a, i) in scale.answer"
                        :key="a + i"
                      >
                        <input class="form-check-input" type="radio" disabled />
                        <label class="form-check-label">{{ a.text }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4 justify-content-end">
            <button
              type="button"
              style="width: auto"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              type="button"
              style="width: auto"
              class="btn btn-success"
              @click="addSubQuestion()"
            >
              Tambah
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import API from "../../utils/API";
import $ from "jquery";

export default {
  data() {
    return {
      selectedSection: null,
      selectedQuestion: null,
      selectedQuestionPos: null,
      surveyQuestions: null,
      sectionList: null,
      scaleList: [
        {
          value: "Guna Skala 5",
          answer: [
            { text: "Sangat Rendah" },
            { text: "Rendah" },
            { text: "Sederhana" },
            { text: "Tinggi" },
            { text: "Sangat Tinggi" },
          ],
        },
        {
          value: "Guna Skala 4",
          answer: [
            { text: "Lemah" },
            { text: "Sederhana" },
            { text: "Baik" },
            { text: "Cemerlang" },
          ],
        },
      ],
      subQForm: {
        question: null,
        answer: null,
      },
      answersForm: null,
    };
  },
  watch: {
    selectedSection(section) {},
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const query = qs.stringify(
        {
          pagination: {
            start: 0,
            limit: 100,
          },
          populate: ["answer", "subquestion.answer"],
        },
        {
          encodeValuesOnly: true,
        }
      );

      const res = await API.get(`survey-questions?${query}`);
      const processedData = res.data.data.map((o) => {
        return Object.assign({}, o.attributes, { id: o.id }, { isEdit: false });
      });
      this.sectionList = [...new Set(processedData.map((o) => o.section))];
      this.sectionList.sort();
      this.surveyQuestions = processedData.reduce((acc, cur) => {
        acc[cur["section"]] = [...(acc[cur["section"]] || []), cur];
        return acc;
      }, {});
      console.log(this.sectionList);
      console.log(this.surveyQuestions[this.sectionList[0]]);
    },
    openAddSubQuestion(q, i) {
      (this.subQForm = {
        question: null,
        answer: null,
      }),
        (this.selectedQuestion = q);
      this.selectedQuestionPos = i;
    },
    addSubQuestion() {
      this.surveyQuestions[this.selectedSection][
        this.selectedQuestionPos
      ].subquestion.push(this.subQForm);

      $("#addSubQuestion").toggle();
      $(".modal-backdrop").remove();
    },
    async submitAddSubQuestion() {
      const res = await API.put(
        `survey-questions/${this.selectedQuestion.id}`,
        {
          data: {
            subquestion:
              this.surveyQuestions[this.selectedSection][
                this.selectedQuestionPos
              ].subquestion,
          },
        }
      );
      this.surveyQuestions[this.selectedSection][
        this.selectedQuestionPos
      ].isEdit = false;
    },
    openAddAnotherAnswer(q, i) {
      this.answersForm = [];
      if (q.answer.length > 0) this.answersForm = q.answer;
      this.selectedQuestion = q;
      this.selectedQuestionPos = i;
    },
    addAnotherAnswer() {
      let answerObj = {
        text: "",
      };
      if (this.answersForm.length == 0) this.answersForm.push(answerObj);
      else {
        if (this.answersForm[this.answersForm.length - 1].text !== "")
          this.answersForm.push(answerObj);
      }
    },
    async submitAddAnswer() {
      const res = await API.put(
        `survey-questions/${this.selectedQuestion.id}`,
        { data: { answer: this.answersForm } }
      );
      Object.assign(
        this.surveyQuestions[this.selectedSection][this.selectedQuestionPos],
        res.data.data
      );
      $("#addAnswerModal").toggle();
      $(".modal-backdrop").remove();
    },
    async deleteSurveyQuestion(q, i) {
      const res = await API.delete(`survey-questions/${q.id}`);
      if (res.toDelete) this.surveyQuestions[this.selectedSection].splice(i, 1);
    },
  },
  async mounted() {
    await this.fetchData();
    this.selectedSection = this.sectionList[0];
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.contents {
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: $gray-reqular !important;

        &.active {
          border: unset;
          border-bottom: 1px solid $green;
          color: $green !important;
        }
      }
    }
  }
}
</style>